import React, {Component} from 'react';
import {Link} from 'react-router-dom';

var img10 = require('./../../images/city/bsas.jpeg');
var img20 = require('./../../images/city/pic4.jpg');
var img30 = require('./../../images/city/uk.jpeg');
var img40 = require('./../../images/city/canada.jpeg');

var img50 = require('./../../images/city/brazil.png');
var img60 = require('./../../images/city/world.jpeg');
var img70 = require('./../../images/city/sidney.jpeg');
var img80 = require('./../../images/city/munich.jpeg');

class Featureblog extends Component {
    render() {
        return (
            <div className="section-full content-inner bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 section-head text-center">
                            <h2 className="m-b5">Explore Jobs By Location</h2>
                            <h6 className="fw4 m-b0">You can search for jobs by country and city</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/remote-jobs-worldwide'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img60 + ")"}}>
                                <div className="city-info">
                                    <h5>Worldwide <span role="img" aria-label="World Flag">🌎</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-usa'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img20 + ")"}}>
                                <div className="city-info">
                                    <h5>USA <span role="img" aria-label="United States Flag">🇺🇸</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-canada'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img40 + ")"}}>
                                <div className="city-info">
                                    <h5>Canada <span role="img" aria-label="Canada Flag">🇨🇦</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-australia'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img70 + ")"}}>
                                <div className="city-info">
                                    <h5>Australia <span role="img" aria-label="Australia Flag">🇦🇺</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-argentina'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img10 + ")"}}>
                                <div className="city-info">
                                    <h5>Argentina <span role="img" aria-label="Argentina Flag">🇦🇷</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-brazil'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img50 + ")"}}>
                                <div className="city-info">
                                    <h5>Brazil <span role="img" aria-label="Brazil Flag">🇧🇷</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-germany'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img80 + ")"}}>
                                <div className="city-info">
                                    <h5>Germany <span role="img" aria-label="Germany Flag">🇩🇪</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
                            <Link to={'/jobs-in-united-kingdom'}>
                            <div className="city-bx align-items-end  d-flex" style={{backgroundImage: "url(" + img30 + ")"}}>
                                <div className="city-info">
                                    <h5>UK <span role="img" aria-label="United Kingdom Flag">🇬🇧</span></h5>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center m-t30" style={{display: ""}}>
                            <Link to={"/all-jobs-by-location"} className="dez-tilte"><button className="site-button radius-l button-lg">View All Locations</button></Link>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="job-bx-title clearfix">
                                                        <h6 className="text-uppercase">Jobs By Country</h6>
                                                    </div>
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs-in-usa"><span role="img" aria-label="USA flag">🇺🇸</span> USA Jobs</Link></li>
                                                        <li><Link to="/jobs-in-argentina"><span role="img" aria-label="Argentina flag">🇦🇷</span> Argentina Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Armenia"><span role="img" aria-label="Armenia flag">🇦🇲</span> Armenia Jobs</Link></li>
                                                        <li><Link to="/jobs-in-australia"><span role="img" aria-label="Australia flag">🇦🇺</span> Australia Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Austria"><span role="img" aria-label="Austria flag">🇦🇹</span> Austria Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Belgium"><span role="img" aria-label="Belgium flag">🇧🇪</span> Belgium Jobs</Link></li>
                                                        <li><Link to="/jobs-in-brazil"><span role="img" aria-label="Brazil flag">🇧🇷</span> Brazil Jobs</Link></li>
                                                        <li><Link to="/jobs?title=&location=Bulgaria"><span role="img" aria-label="Bulgaria flag">🇧🇬</span> Bulgaria Jobs</Link></li>
                                                        <li><Link to="/jobs-in-canada"><span role="img" aria-label="Canada flag">🇨🇦</span> Canada Jobs</Link></li>
                                                        <li><Link to="/jobs-in-chile"><span role="img" aria-label="Chile flag">🇨🇱</span> Chile Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="job-bx-title clearfix">
                                                        <h6 className="text-uppercase">Jobs By US States</h6>
                                                    </div>
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Alabama">Alabama</Link></li>
                                                        <li><Link to="/jobs?title=&location=Alaska">Alaska</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arizona">Arizona</Link></li>
                                                        <li><Link to="/jobs?title=&location=Arkansas">Arkansas</Link></li>
                                                        <li><Link to="/jobs?title=&location=California">California</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado">Colorado</Link></li>
                                                        <li><Link to="/jobs?title=&location=Connecticut">Connecticut</Link></li>
                                                        <li><Link to="/jobs?title=&location=Delaware">Delaware</Link></li>
                                                        <li><Link to="/jobs?title=&location=Florida">Florida</Link></li>
                                                        <li><Link to="/jobs?title=&location=Georgia">Georgia</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="job-bx-title clearfix">
                                                        <h6 className="text-uppercase">Jobs By US Cities</h6>
                                                    </div>
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs?title=&location=Albuquerque">Albuquerque</Link></li>
                                                        <li><Link to="/jobs?title=&location=Atlanta">Atlanta</Link></li>
                                                        <li><Link to="/jobs?title=&location=Austin">Austin</Link></li>
                                                        <li><Link to="/jobs?title=&location=Baltimore">Baltimore</Link></li>
                                                        <li><Link to="/jobs?title=&location=Boston">Boston</Link></li>
                                                        <li><Link to="/jobs?title=&location=Charlotte">Charlotte</Link></li>
                                                        <li><Link to="/jobs?title=&location=Chicago">Chicago</Link></li>
                                                        <li><Link to="/jobs?title=&location=Colorado Spring">Colorado Spring</Link></li>
                                                        <li><Link to="/jobs?title=&location=Cleveland">Cleveland</Link></li>
                                                        <li><Link to="/jobs?title=&location=Columbus">Columbus</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
};

export default Featureblog;
