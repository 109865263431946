import React, { Component } from "react";
//import { Link } from "react-router-dom";
import Jobfindbox from "./../Element/Jobfindbox2";
import Footer from "./../Layout/Footer2";
import Header from "./../Layout/Header";
import Jobcategories from './../Element/Jobcategories';
import Featureblog from './../Element/Featureblog';
//import UnlockJobs from './../Element/UnlockJobs';
import { Helmet } from 'react-helmet';
import ContentJobs from './../Element/ContentJobs';

const axios = require('axios');

class Browsejobgrid extends Component {
    constructor(props) {
        super(props);

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        this.state = {
            jobs: [],
            total: '',
            myparams: params,
        };
    }

    componentDidMount() {
        this.jobsList();
    }

    jobsList() {
        var options = {};
        var headers = {};
        var url = "";

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            url = 'http://localhost:8080/jobs';
        } else {
            url = "https://open-to-work-remote-api.p.rapidapi.com/jobs";
            headers = {
                "X-RapidAPI-Key": "61c95e0e86msh784d6d5dfb4761dp1ed5e9jsn7923c8051dc2",
                "X-RapidAPI-Host": "open-to-work-remote-api.p.rapidapi.com",
                "X-Source": "OpenToWorkRemote.com",
            };
        }

        options = {
            method: "GET",
            url: url,
            params: {
                perPage: params.perPage || 20,
                page: params.page,
                title: params.title,
                location: params.location,
                salaryMin: params.salaryMin,
                tag: params.tag,
                company: params.company,
                source: params.source
            },
            headers: headers
        };

        axios
            .request(options)
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                });
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    render() {
        var { jobs, total, page, pages, prevPage, nextPage, myparams } = this.state;

        let showTopSearch = true;
        if (myparams.title || myparams.location || myparams.salaryMin) {
            showTopSearch = false;
        }

        return (

            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>#OpenToWorkRemote: Find here your dream job</title>
                    <meta name="description" content="Explore a wide range of job opportunities and find your perfect match. Search for jobs, filter by industry, location, salary, and more. Follow us!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr bg-dark">
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <a href="/" className="" rel="noopener noreferrer">
                                    <h1 className="text-white">
                                        Find Your Perfect Remote Job
                                    </h1>
                                </a>
                                <div className="breadcrumb-row">
                                    <ul className="">
                                        <li>Ready to work from anywhere? Find you dream job here!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Jobfindbox />
                    {showTopSearch && (
                    <div className="section-full job-categories content-inner-2 bg-white" style={{display: ""}}>
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="m-b5">Explore Top Remote Searches</h2>
                                <h5 className="fw4">Find jobs categorized perfectly for you!</h5>
                            </div>
                            <Jobcategories />
                        </div>
                    </div>
                    )}

                    <ContentJobs jobs={jobs} total={total} page={page} pages={pages} prevPage={prevPage} nextPage={nextPage} />
                </div>

                <Featureblog />

                <Footer />
            </div>
        );
    }
}

export default Browsejobgrid;
